<script setup lang="ts">
// import { RouterLink } from 'vue-router';
import { useLanguage } from '../composables/useLanguage';
import { Menu, X } from 'lucide-vue-next';
import { computed, ref } from 'vue';
import {
  availableTranslations,
  useTranslations,
} from '../composables/useTranslations';

const { currentLanguage, setLanguage } = useLanguage();
const { translations } = useTranslations();

const links = computed(() => [
  {
    title: translations.value.headlines.whyItWorks,
    to: '#why-it-works',
  },
  {
    title: translations.value.headlines.howItWorks,
    to: '#how-it-works',
  },
  {
    title: translations.value.headlines.targetGroup,
    to: '#target-group',
  },
  {
    title: translations.value.headlines.earlyAccess,
    to: '#early-access',
  },
]);

const languagesDropDown = computed(() => {
  return Object.entries(availableTranslations).map(([key, value]) => ({
    code: key,
    label: value.general.nativeName,
  }));
});

const menuIsShown = ref(false);

function changeLanguage() {
  setLanguage(currentLanguage.value);
  localStorage.setItem('language', currentLanguage.value);
}
</script>

<template>
  <header
    class="lg:px-6 w-full flex items-center justify-between backdrop-blur-md bg-white shadow-sm py-4 px-6 z-50"
    :class="{ 'fixed left-0 top-0': menuIsShown }"
  >
    <div id="header-left-side">
      <div class="flex items-center space-x-4">
        <div class="bg-gradient-to-r from-primary to-secondary p-2 rounded-lg">
          <div class="size-8 rounded-md bg-gray-200"></div>
        </div>
        <h1 class="text-2xl font-bold text-gray-800 select-none">
          Language Rooms
        </h1>
      </div>
    </div>
    <div id="header-right-side" class="text-gray-600 text-sm font-medium">
      <nav class="md:flex hidden gap-5 items-center">
        <ul class="gap-5 flex">
          <li v-for="link in links" :key="link.to">
            <a
              :href="link.to"
              class="text-sm font-medium text-gray-600 hover:text-blue-500 transition-colors"
              >{{ link.title }}</a
            >
          </li>
        </ul>

        <select
          id="languageSelect"
          v-model="currentLanguage"
          class="bg-transparent text-sm font-medium outline-none appearance-none text-gray-600 hover:text-blue-500 transition-colors cursor-pointer"
          @change="changeLanguage"
        >
          <option
            v-for="language in languagesDropDown"
            :key="language.code"
            :value="language.code"
          >
            {{ language.label }}
          </option>
        </select>
      </nav>
      <nav
        v-if="menuIsShown"
        class="flex w-full py-4 px-6 flex-col absolute backdrop-blur-md left-0 top-20 md:hidden gap-5 items-start bg-white h-screen"
      >
        <ul class="gap-5 flex flex-col">
          <li v-for="link in links" :key="link.to">
            <a
              :href="link.to"
              class="text-lg font-medium text-gray-600 hover:text-blue-500 transition-colors"
              @click="menuIsShown = false"
              >{{ link.title }}</a
            >
          </li>
        </ul>

        <div class="flex gap-1">
          <span class="text-lg">{{ translations.texts.siteLanguage }}: </span>
          <select
            id="languageSelect"
            v-model="currentLanguage"
            class="bg-transparent text-lg font-medium outline-none appearance-none hover:text-blue-500 transition-colors cursor-pointer"
            @change="changeLanguage"
          >
            <option
              v-for="language in languagesDropDown"
              :key="language.code"
              :value="language.code"
            >
              {{ language.label }}
            </option>
          </select>
        </div>
      </nav>
      <button
        class="md:hidden hover:bg-gray-200 p-3 rounded-md"
        @click="menuIsShown = !menuIsShown"
      >
        <Menu v-if="!menuIsShown"></Menu>
        <X v-if="menuIsShown"></X>
      </button>
    </div>
  </header>
</template>
