export const de = {
  general: {
    nativeName: 'Deutsch',
    language: 'Sprache',
  },
  greetings: {
    hello: 'Hallo',
    welcome: 'Willkommen',
    enterName: 'Gib deinen Namen ein',
  },
  difficulties: {
    Beginner: 'Anfänger',
    Intermediate: 'Fortgeschrittene',
    Advanced: 'Experten',
    whoIsThisLevelFor: 'Für wen ist dieses Level?',
    descriptions: {
      Beginner:
        'Anfängerräume sind ideal, wenn du neu in der Sprache bist. Sie sind perfekt, um grundlegende Sätze und einfache Gespräche zu üben. Du wirst Selbstvertrauen beim Sprechen und Verstehen grundlegender Konzepte aufbauen und dich auf wesentliche Grammatik und Alltagsvokabular konzentrieren.',
      Intermediate:
        'Wenn du einfache Gespräche führen kannst und deine Flüssigkeit verbessern möchtest, sind die Räume für Fortgeschrittene genau richtig für dich. Du wirst an komplexeren Diskussionen teilnehmen, Sprachnuancen erkunden und beginnen, idiomatische Ausdrücke zu verstehen. Arbeite daran, deine Grammatik zu verfeinern und komplexere Ideen auszudrücken.',
      Advanced:
        'Expertenräume sind für hochkompetente Lernende gedacht, die eine nahezu muttersprachliche Flüssigkeit anstreben. Wenn du selbstbewusst über komplexe Themen diskutieren kannst, sind diese Räume genau das Richtige für dich. Du wirst dich an anspruchsvollen Diskussionen über Literatur, Kultur und aktuelle Ereignisse beteiligen und mit fortgeschrittenem Vokabular und subtilen Sprachnuancen arbeiten.',
    },
  },
  headlines: {
    rooms: 'Räume',
    whyItWorks: 'Warum es funktioniert',
    howItWorks: 'Wie es funktioniert',
    targetGroup: 'Zielgruppe',
    earlyAccess: 'Frühzeitiger Zugang',
    speakLanguagesAndImproveFluency: 'Verbessere deine Sprachkenntnisse mit',
    whyLanguageRoomsWorks: 'Warum Language Rooms funktioniert',
    theLanguageRoomsMethod: 'Die Language Rooms Methode',
    howLanguageRoomsWorks: 'Wie Language Rooms funktioniert',
    becomeALanguageRoomsPioneer: 'Werde ein Language Rooms Pionier',
    yourVoiceMatters: 'Deine Stimme zählt',
    perfectForIntermediateLearnersAndBeyond:
      'Perfekt für fortgeschrittene Lerner und darüber hinaus',
    whoBenefitsMost: 'Wer profitiert am meisten',
    ourExcitingRoadmap: 'Unsere spannende Roadmap',
    whyYourFeedBackIsCrucial: 'Dein Feedback ist entscheidend',
    howToProvideFeedback: 'Wie du Feedback geben kannst',
    speakEarlySpeakOften: 'Sprich früh, sprich oft',
    learnFromNativeSpeakers: 'Lerne von Muttersprachlern',
    embraceTheFun: 'Sprechen macht Spaß',
    progressingLearners: 'Fortschreitende Lernende',
    rustySpeakers: 'Sprachskills auffrischen',
    skillMaintainers: 'Sprachskills beibehalten',
    culturalEnthusiasts: 'Kulturbegeisterte',
    joinARoom: 'Tritt einem Raum bei',
    startSpeaking: 'Fang an zu sprechen',
    levelUpYourLingo: 'Verbessere deine Sprache',
    phaseOneLaunch: 'Phase 1: Start',
    phaseTwoCommunityGrowth: 'Phase 2: Community Wachstum',
    phaseThreeGlobalScale: 'Phase 3: Globale Skalierung',
    traditionalMethod: 'Traditionelle Methode',
    languageRoomsMethod: 'Language Rooms Methode',
  },
  buttons: {
    join: 'Beitreten',
    save: 'Speichern',
    cancel: 'Abbrechen',
    leave: 'Verlassen',
    checkAudioDevices: 'Audiogeräte überprüfen',
    mute: 'Stummschalten',
    unmute: 'Stummschaltung aufheben',
    back: 'Zurück',
    joinTheWaitlist: 'Der Warteliste beitreten',
    exploreRooms: 'Räume erkunden',
    requestEarlyAccess: 'Frühzeitigen Zugang anfordern',
    earlyAccessRequested: 'Frühzeitiger Zugang angefordert',
  },
  texts: {
    allRightsReservedFunny:
      "Alle Rechte vorbehalten. Jetzt geh und übe deine gerollten R's!",
    speakWithNativeSpeakersAndFellowLearners:
      'Sprich mit Muttersprachlern und anderen Lernenden aus der ganzen Welt. Lehrbücher alleine können deine Sprachskills nicht verbessern (glaub uns, wir haben es versucht).',
    theFoundersJourneyLedToDiscovery:
      'Die Spracherfahrungen unseres Gründers führten zu einer bahnbrechenden Entdeckung: Eine Sprache zu sprechen hilft dir, sie zu lernen! Revolutionär, wissen wir.',
    thisExperienceWasntJustAPersonalVictory:
      'Diese Erfahrung war nicht nur ein persönlicher Sieg. Sie wurde zur Grundlage von Language Rooms. Wir haben deshalb drei Kernprinzipien in Language Rooms.',
    languageRoomsIsDesignedTo:
      'Language Rooms ist darauf ausgelegt, deine Fähigkeiten auf die nächste Stufe zu heben. Ob du deine Sprachkenntnisse verbessern, aufrechterhalten oder wiedererlangen möchtest, unsere Plattform bietet die perfekte Umgebung.',
    wereGraduatelyOpeningOurDoors:
      'Wir öffnen schrittweise unsere Türen. Tritt unserer Warteliste für einen frühen Zugang bei und sei einer der Ersten, die die Zukunft des Sprachenlernens erleben!',
    weValueYourInput:
      'Wir schätzen deinen Input! Hilf mit, die Zukunft von Language Rooms zu gestalten, indem du deine Ideen, Wünsche und Erfahrungen teilst.',
    creator: 'Ersteller',
    participants: 'Teilnehmer',
    roomName: 'Raumname',
    roomDescription: 'Raumbeschreibung',
    roomCreatedBy: 'Raum erstellt von',
    roomCreatedAt: 'Raum erstellt am',
    createRoom: 'Raum erstellen',
    joinRoom: 'Raum beitreten',
    joinLanguageRoom: 'Sprachraum beitreten',
    languageRoom: 'Sprachraum',
    room: 'Raum',
    roomSettings: 'Raumeinstellungen',
    roomMembers: 'Raummitglieder',
    roomMessages: 'Raumnachrichten',
    loading: 'Lädt...',
    nobodyHere: 'Niemand hier',
    you: 'Du',
    selectYourDesiredAudioDevices: 'Wähle deine gewünschten Audiogeräte',
    makeSureYouHaveTheCorrectAudioDevicesSelected:
      'Stelle sicher, dass du die richtigen Audiogeräte ausgewählt hast',
    makeSureYouHaveAllowedMicrophoneAccess:
      'Stelle sicher, dass du dem Browser erlaubt hast, auf dein Mikrofon zuzugreifen',
    microphone: 'Mikrofon',
    speakers: 'Lautsprecher',
    difficulty: 'Schwierigkeitsgrad',
    audioSettings: 'Audioeinstellungen',
    enterYourEmail: 'Gib deine E-Mail-Adresse ein',
    weAreExcitedToWelcomeYouSoon:
      'Wir freuen uns darauf, dich bald begrüßen zu dürfen!',
    onceYourEmailIsApproved:
      'Sobald deine E-Mail-Adresse genehmigt wurde, erhältst du vollen Zugang zu Language Rooms und kannst sofort mit deiner Sprachreise beginnen!',
    lookForTheFeedbackButton:
      'Suche nach dem "Feedback geben" Button unten rechts auf jeder Seite. Dein Feedback hilft uns, die bestmögliche Sprachlernerfahrung zu schaffen.',
    sevenYears: '7 Jahre',
    threeMonths: '3 Monate',
    yearsOfTextBooksAndGrammar:
      'Jahre des Lernens mit Lehrbüchern und Grammatikübungen führten dazu, Verben perfekt beugen zu können. Allerdings auch zu Panik in echten Gesprächen.',
    immersiveLearningAndRealConversations:
      'Echte Gespräche führten in nur drei Monaten zu selbstbewussten Spanischkenntnissen. Keine Panikattacken beim Bestellen von Kaffee oder beim Smalltalk.',
    noMoreYearsOfSilentStudy:
      'Fang so schnell wie möglich an zu sprechen, auch wenn es nur "Hola" ist. So begann meine Reise mit Spanisch.',
    textBooksCantTeachYou:
      'Lehrbücher können dir nicht beibringen, wie Einheimische wirklich sprechen. Unsere Plattform verbindet dich mit Muttersprachlern für authentischen, praktischen Sprachgebrauch.',
    learningALanguageShouldBeFun:
      'Eine Sprache zu lernen sollte Spaß machen. Wir haben in jeden Aspekt von Language Rooms Spaß eingebaut, weil wir aus erster Hand wissen, dass es funktioniert.',
    alreadySpeakingALittle:
      'Sprichst du schon ein bisschen? Beschleunige deinen Fortschritt durch regelmäßige Gesprächsübungen mit Muttersprachlern und anderen Lernenden.',
    outOfPracticeQuestion:
      'Aus der Übung? Gewinne schnell dein Selbstvertrauen und deine Sprachskills in einer unterstützenden Umgebung mit geduldigen Gesprächspartnern zurück.',
    dontWantToLoseYourSkills:
      'Möchtest du deine Sprachskills nicht verlieren? Erhalte und verbessere deine Sprachkenntnisse mühelos durch regelmäßige, fesselnde Gespräche über Themen, die du liebst.',
    diveDeeperIntoTheCultures:
      'Tauche tiefer in die Kulturen hinter den Sprachen ein, die du lernst. Verbinde dich mit Muttersprachlern und gewinne spannende Einblicke in neue Kulturen.',
    pickAThemedRoom:
      'Wähle einen thematischen Raum, der zu deinen Interessen passt. "Kaffee bestellen ohne Panikattacken" ist eine beliebte Wahl.',
    startSpeakingWithOthers:
      'Fang an, mit anderen Lernenden und Muttersprachlern zu sprechen, die deinen Akzent nicht (zu sehr) beurteilen werden.',
    watchYourLanguageSkillsFlourish:
      'Beobachte dich dabei, wie deine Sprachkenntnisse immer besser werden. Bald wirst du in deiner Zielsprache träumen!',
    coreFeaturesAndInitialFeatures:
      'Kernfunktionen und erste Sprachräume für Nutzer mit frühem Zugang verfügbar.',
    expandUserbase:
      'Erweiterung der Nutzerbasis, Einführung weiterer Sprachen und Verbesserung der Community-Funktionen.',
    worldwideAccessibility:
      'Weltweite Zugänglichkeit, kulturelle Austauschprogramme und fortgeschrittene Funktionen.',
    shapeTheFutureOfLanguageRooms: 'Gestalte die Zukunft von Language Rooms',
    suggestNewFeaturesAndImprovements:
      'Schlage neue Funktionen oder Verbesserungen vor',
    reportAnyIssues: 'Melde alle Probleme, die du findest',
    shareYourSuccessStories: 'Teile deine Erfolgsgeschichten',
    extensiveGrammarFocus: 'Umfassender Grammatikfokus',
    vocabularyMemorization: 'Vokabeln auswendig lernen',
    limitedPracticalApplication: 'Begrenzte praktische Anwendung',
    focusOnPracticalUsage: 'Fokus auf praktischer Anwendung',
    dailyConversationPractice: 'Tägliche Gesprächsübungen',
    highConfidenceInRealSituations:
      'Hohes Selbstvertrauen in realen Situationen',
    siteLanguage: 'Seitensprache',
  },
  errors: {
    error: 'Fehler',
    localStreamNotFound:
      'Lokaler Stream nicht gefunden. Stelle sicher, dass du dem Browser erlaubt hast, auf deine Kamera und dein Mikrofon zuzugreifen.',
  },
} as const;
